import axios from "axios";

import { formatDynamicLink } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createDynamicLink = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/dynamic-link`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatDynamicLink(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllDynamicLinksOfBand = async (bandId) => {
  try {
    const urlToQuery = `${baseUrl}/dynamic-links?bandId=${bandId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map((dynamicLink) => formatDynamicLink(dynamicLink));
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateDynamicLink = async (dynamicLinkId, editData) => {
  try {
    const urlToQuery = `${baseUrl}/dynamic-link?id=${dynamicLinkId}`;
    const axiosResponse = await axios.patch(urlToQuery, editData);
    const { data } = axiosResponse;
    return formatDynamicLink(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteDynamicLink = async (dynamicLinkId) => {
  try {
    const urlToQuery = `${baseUrl}/dynamic-link`;
    const axiosResponse = await axios.delete(urlToQuery, { id: dynamicLinkId });
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllDynamicLinksOfGig = async (gigId) => {
  try {
    const urlToQuery = `${baseUrl}/gig-dynamic-links?gigId=${gigId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map((dynamicLink) => formatDynamicLink(dynamicLink));
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createDynamicLinkForGig = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/gig-dynamic-link`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateDynamicLinkForGig = async (dynamicLinkId, gigId, isActive) => {
  try {
    const urlToQuery = `${baseUrl}/gig-dynamic-link?id=${dynamicLinkId}gigId=${gigId}isActive=${isActive}`;
    const axiosResponse = await axios.patch(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  createDynamicLink,
  getAllDynamicLinksOfBand,
  updateDynamicLink,
  deleteDynamicLink,
  getAllDynamicLinksOfGig,
  createDynamicLinkForGig,
  updateDynamicLinkForGig,
};
